"use client";
import React, { useState } from "react";
import SmallModal from "../SmallModal";
import useCreatedUserModal from "@/hooks/useCreatedUserModal";
import CreatedUserMessage from "./CreatedUserMessage";
import { signIn } from "next-auth/react";
import useRegisterModal from "@/hooks/useRegisterModal";

interface Props {
  email: string;
  password: string;
}

function CreatedUserModal({ email, password }: Props) {
  const createdUserModal = useCreatedUserModal();
  const [isLoading, setIsLoading] = useState(false);
  const registerModal = useRegisterModal();

  // Redirect URL after successful login.
  const redirectUrl = "/user/onboarding";

  const onSubmit = async () => {
    setIsLoading(true);
    //Next Auth signIn function
    const result = await signIn("credentials", {
      email: email,
      password: password,
      callbackUrl: redirectUrl,
      redirect: true,
    });
    registerModal.onClose();
    setIsLoading(false);
  };

  return (
    <>
      <SmallModal
        isOpen={createdUserModal.isOpen}
        title="Usuario Creado"
        onClose={createdUserModal.onClose}
        body={<CreatedUserMessage />}
        actionLabel="Continuar"
        onSubmit={onSubmit}
      />
    </>
  );
}

export default CreatedUserModal;
