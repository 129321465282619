"use client";
import LinksNavigation from "@/constants/navigation";
import Image from "next/image";
import React from "react";

function SupportLink() {
  const links = LinksNavigation();

  return (
    <>
      <div
        onClick={links.handleClickWhatsappBooking}
        className=" flex cursor-pointer items-center align-middle"
      >
        <Image
          src={"/assets/images/Whatsapp2.svg"}
          alt={"whatsapp"}
          width={0}
          height={0}
          priority
          className="cursor-pointer w-[30px] h-auto"
        />
        <p className="text-violet-700 pl-2 underline">Consultar a soporte</p>
      </div>
    </>
  );
}

export default SupportLink;
