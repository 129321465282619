import React from "react";
import LinksNavbarItem from "./LinksNavbarItem";
import useMenuOptionsData from "@/constants/menuData";

function LinksNavBar() {
  const menu = useMenuOptionsData();

  return (
    <div className="w-full hidden lg:flex items-center justify-end space-x-4 lg:space-x-8">
      {menu.NavBarMenuData().map(({ onClick, label }) => (
        <LinksNavbarItem key={label} label={label} onClick={onClick} />
      ))}
    </div>
  );
}

export default LinksNavBar;
