"use client";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Bars3Icon } from "@heroicons/react/24/solid";
import MenuItems from "./MenuItems";
import useMenuOptionsData from "@/constants/menuData";
import LinksNavigation from "@/constants/navigation";
import Avatar from "./Avatar";
import { useSession } from "next-auth/react";
import { getUserInformation } from "@/services/api/user";
import { User } from "@/services/models/user";
import useOnclickOutside from "react-cool-onclickoutside";
import WhatsappButton from "./WhatsappButton";

interface Props {
  isHomePage?: Boolean;
}

/**
 * UserMenu component displays the user menu with various options based on the user's authentication status.
 */

function UserMenu({ isHomePage }: Props) {
  const { data: session } = useSession();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menu = useMenuOptionsData();
  const [user, setUser] = useState<User>();

  // Fetch user information from the server.
  const fetchData = useMemo(
    () => async () => {
      try {
        const accessToken = session?.user.accessToken;
        if (accessToken) {
          const userInfo = await getUserInformation({
            token: accessToken,
          });
          setUser(userInfo);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    [session?.user.accessToken]
  );

  // Fetch user data when the component mounts or when the session data changes.
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Generate the menu items based on the user's authentication status.
  const menuItems = useMemo(() => {
    if (session) {
      return menu.AuthData();
    }
    return menu.NotAuthData();
  }, [menu, session]);

  // Toggle the menu open/closed state.
  const toggleOpen = useCallback(() => {
    setIsMenuOpen((value) => !value);
  }, []);

  //Handle clicks outside the menu to close it.
  const refMenu = useOnclickOutside(() => {
    setIsMenuOpen(false);
  });

  return (
    <>
      <div className="flex items-center space-x-3 justify-end text-gray-500">
        {isHomePage && <WhatsappButton isBooking />}

        {/* Render the user menu icon and avatar */}
        <div
          ref={refMenu}
          className="flex items-center space-x-1  p-2 rounded-xl hover:bg-gray-100 hover:scale-105"
          onClick={toggleOpen}
        >
          <Bars3Icon className="h-6" />

          <div className="flex w-[40px] h-[40px] rounded-full object-containt overflow-hidden border-white">
            <Avatar photo={user?.avatar} width={40} height={40} />
          </div>
        </div>
      </div>

      {/* Render the user menu options when the menu is open */}
      {isMenuOpen && (
        <div
          ref={refMenu}
          className="absolute rounded-xl shadow-lg w-[100vw] md:w-[320px] md:mr-2 bg-white overflow-hidden right-0 top-[72px] text-sm"
        >
          <div>
            {menuItems.map(({ onClick, label, icon, divider }) => (
              <MenuItems
                key={label}
                label={label}
                icon={icon}
                onClick={onClick}
                divider={divider}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default UserMenu;
