import React from "react";

interface Props {
  title?: string;
  subtitle?: string;
  center?: Boolean;
}

function FormsHeading({ title, subtitle, center }: Props) {
  return (
    <div className={center ? "text-center" : "text-start pb-3"}>
      <div className="text-xl font-bold">{title}</div>
      <div className="text-sm font-light text-neutral-500 mt-2">{subtitle}</div>
    </div>
  );
}

export default FormsHeading;
