"use client";
import { useState, useEffect } from "react";
import SmallModal from "../SmallModal";
import ValidatePhoneUserMessage from "./ValidatePhoneUserMessage";
import useValidatePhoneUserModal from "@/hooks/useValidatePhoneUser";
import { createUser, phoneCodeVerification } from "@/services/api/user";
import AlertModal from "../createVehicle/AlertModal";
import useAlertModal from "@/hooks/useAlertModal";
import useCreatedUserModal from "@/hooks/useCreatedUserModal";

interface Props {
  name: string;
  lastname: string;
  phone: string;
  email: string;
  password: string;
}
function ValidatePhoneUserModal({
  name,
  lastname,
  phone,
  email,
  password,
}: Props) {
  const [code, setCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const validatePhoneUserModal = useValidatePhoneUserModal();
  const alertModal = useAlertModal();
  const [subtittle, setSubtittle] = useState("");
  const [showSupportLink, setShowSupportLink] = useState(false);
  const createdUserModal = useCreatedUserModal();

  const createNewUser = async () => {
    try {
      const body = {
        email: email,
        password: password,
        phone: phone,
        first_name: name,
        last_name: lastname,
      };

      const res = await createUser({
        body: body,
      });
      if (res.code_transaction === "OK") {
        // Enviar el evento de registro al dataLayer
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "user_registration",
          user: {
            email: email,
            name: name,
            lastname: lastname,
            phone: phone,
          },
        });

        createdUserModal.onOpen();
        validatePhoneUserModal.onClose();
      }
    } catch (error) {
      console.error("Error creating user:", error);
      setSubtittle(
        "Hemos tenido un error interno. Intenta nuevamente. Si el error persiste, comunícate con soporte."
      );
      setShowSupportLink(true);
      alertModal.onOpen();
    }
  };

  const validatePhoneCode = async () => {
    if (!phone) {
      console.error("Phone number is not available for validation");
      return;
    }

    setIsLoading(true);
    try {
      const body = {
        phone: phone,
        code: code,
      };
      const res = await phoneCodeVerification({
        body: body,
      });
      if (res.code_transaction === "OK") {
        await createNewUser();
      } else {
        throw new Error("Invalid phone code");
      }
    } catch (error) {
      console.error("Error validating phone code:", error);
      setSubtittle("¡Código invalido! Intenta nuevamente.");
      setShowSupportLink(false);
      alertModal.onOpen();
    }
    setIsLoading(false);
  };

  return (
    <>
      <SmallModal
        isOpen={validatePhoneUserModal.isOpen}
        title="Validación"
        onClose={validatePhoneUserModal.onClose}
        body={<ValidatePhoneUserMessage phone={phone} setCode={setCode} />}
        actionLabel="Continuar"
        onSubmit={validatePhoneCode}
        disabled={isLoading}
      />
      <AlertModal
        title={"Ups! Algo salió mal!"}
        subtitle={subtittle}
        support={showSupportLink}
      />
    </>
  );
}

export default ValidatePhoneUserModal;
