"use client";
import format from "date-fns/format";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useState } from "react";
import PlacesAutocomplete from "../../Search/Maps/PlacesAutocomplete";
import ModalsButton from "../../Buttons/ModalsButton";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import useOnclickOutside from "react-cool-onclickoutside";
import DateTimePicker from "./DateTimePicker";
import useBookingDates from "@/hooks/useBookingDates";
import SearchCars from "@/commons/CarSearch";
import { addDays } from "date-fns";

interface Props {
  landingPage?: boolean;
}

const SearchBar = ({ landingPage }: Props) => {
  //ScreenSize variables
  const [isLarge, setIsLarge] = useState(window.innerWidth >= 768);
  const [isMedNavBar, setIsMedNavBar] = useState(
    window.innerWidth >= 1024 && !landingPage
  );
  const [placeName, setPlaceName] = useState("");

  //booking date and hours variables
  const {
    location,
    place,
    startDate,
    startHour,
    endDate,
    endHour,
    setLocation,
    setPlace,
    setStartDate,
    setStartHour,
    setEndDate,
    setEndHour,
  } = useBookingDates();
  const searchCars = SearchCars();
  //Controler data picker show status
  const [showSearchBox, setShowSearchBox] = useState(false);
  //Locaction variables

  const placeLabel = "Lugar de entrega:";
  const toDateLabel = "Hasta:";

  // Effect to adjust the UI based on window resize
  useEffect(() => {
    function handleResize() {
      setIsLarge(window.innerWidth >= 768);
      setIsMedNavBar(window.innerWidth >= 1024);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Verificar si endDate es menor que startDate
  useEffect(() => {
    if (endDate < startDate) {
      const newEndDate = addDays(startDate, 3);
      setEndDate(newEndDate);
    }
  }, [startDate, endDate, setEndDate]);

  // Function to initiate car search based on selected options
  function carsSearch() {
    // Agregar Google Tag Manager event tracking
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "car_search",
        category: "Car Search",
        action: "Search",
        label: `Search for cars in ${placeName}`,
        searchDetails: {
          location: placeName,
          startDate: startDate ? format(startDate, "yyyy-MM-dd") : "N/A",
          endDate: endDate ? format(endDate, "yyyy-MM-dd") : "N/A",
        },
      });
    }

    searchCars.handleSearch(location);
    setPlace(placeName);
    setShowSearchBox(false);
  }

  // Function to handle location selection
  const handleLocationSelect = (lat: any, lng: any, placeName: string) => {
    const coordinatesString = `${lat},${lng}`;
    setLocation(coordinatesString);
    setPlaceName(placeName);
  };

  // Click outside hook for closing search box
  const refBoxSearch = useOnclickOutside(() => {
    setShowSearchBox(false);
  });

  function RenderFromForm() {
    return (
      <>
        <DateTimePicker
          label="Desde:"
          pickDate={startDate}
          setPickDate={setStartDate}
          hour={startHour}
          setHour={setStartHour}
        />
      </>
    );
  }

  function RenderToForm() {
    return (
      <>
        <DateTimePicker
          label={toDateLabel}
          pickDate={endDate}
          setPickDate={setEndDate}
          hour={endHour}
          setHour={setEndHour}
          minDate={startDate}
        />
      </>
    );
  }

  // Function to render the mini search bar (search box)
  function RenderMiniSearchBar() {
    return (
      <>
        <div className="absolute left-0 px-8 sm:px-16 w-full">
          <div
            ref={refBoxSearch}
            className="items-center bg-white rounded-lg px-6 py-3 border-2 shadow-md"
          >
            {/* Place selection */}
            <div className="w-full py-1 border-b ">
              <p className=" text-[11px] font-semibold ">{placeLabel}</p>
              <div className="pl-6">
                <PlacesAutocomplete onSelect={handleLocationSelect} />
              </div>
            </div>

            {/* Date selection */}
            <div className="w-full py-1  border-b">
              <div className="-ml-4">{RenderFromForm()}</div>
            </div>
            <div className="w-full py-1  border-b">
              <div className="-ml-4">{RenderToForm()}</div>
            </div>
            {/* Search button */}
            <div className="pt-3">
              <ModalsButton label={"Buscar"} onClick={carsSearch} small />
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {(isLarge && landingPage) || (isMedNavBar && !landingPage) ? (
        <>
          {/* Large screen view */}
          <div
            className={`flex items-center rounded-full py-2 h-16 bg-white ${
              landingPage ? "md:border-2 md:shadow-md " : ""
            }`}
          >
            <div className="flex-grow grid grid-cols-10">
              {/* Place selection */}
              <div className={"col-span-4"}>
                <p className=" text-[11px] font-semibold pl-6">{placeLabel}</p>
                <div
                  className={`pl-8  ${
                    landingPage ? "" : "border-b border-gray-300 mx-2"
                  }`}
                >
                  <PlacesAutocomplete onSelect={handleLocationSelect} />
                </div>
              </div>

              {/* Date selection */}
              {/* From */}
              <div
                className={`col-span-3 w-full ${
                  landingPage ? "border-l-2 border-gray-200" : ""
                }`}
              >
                <div
                  className={`flex items-center align-middle  ${
                    landingPage ? "" : "border-b border-gray-300 mx-1"
                  }`}
                >
                  {RenderFromForm()}
                </div>
              </div>
              {/* to */}
              <div
                className={`col-span-3 w-full ${
                  landingPage ? "border-l-2 border-gray-200" : ""
                }`}
              >
                <div
                  className={`flex items-center align-middle  ${
                    landingPage ? "" : "border-b border-gray-300 mx-1"
                  }`}
                >
                  {RenderToForm()}
                </div>
              </div>
            </div>

            {/* Search button */}
            <div className=" mr-2 w-10">
              <MagnifyingGlassIcon
                className={`h-8 text-white rounded-full p-2 cursor-pointer hidden md:inline-flex md:mx-2 hover:shadow-lg hover:scale-105
                ${landingPage ? "bg-violet-700" : "bg-black"}`}
                onClick={carsSearch}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          {/* Small screen view */}
          {landingPage ? (
            <>
              <div className="w-full"></div>
              {RenderMiniSearchBar()}
            </>
          ) : (
            <>
              {/* Display when search box is not open */}
              {!showSearchBox && (
                <>
                  <div
                    onClick={() => {
                      setShowSearchBox(!showSearchBox);
                    }}
                    className="bg-gray-100 py-4 px-6 flex justify-between items-center w-full cursor-pointer "
                  >
                    <div className="truncate overflow-hidden text-ellipsis">
                      <p className="font-bold text-xs ">
                        {place ? place : "Ciudad, aeropuerto, dirección"}
                      </p>
                      <p className="font-bold text-xs ">
                        {startDate ? format(startDate, "dd/MM/yyyy") : ""}
                        {" - "}
                        {endDate ? format(endDate, "dd/MM/yyyy") : ""}
                      </p>
                    </div>
                    <ChevronDownIcon className="pl-3 h-6 text-violet-700" />
                  </div>
                </>
              )}
              {showSearchBox && (
                <>
                  {/* Display when search box is open */}
                  <div className="fixed top-20 left-0 w-full p-10 h-screen bg-gray-500 bg-opacity-40">
                    {RenderMiniSearchBar()}
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default React.memo(SearchBar);
``;
