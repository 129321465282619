import GuideImage from "@/components/Guide/GuideImage";
import React from "react";

function CreatedUserMessage() {
  return (
    <>
      <div className="sitems-center">
        <div className="flex w-full justify-center m-auto mb-10 mt-4">
          <GuideImage image={"good.svg"} />
        </div>
        <p className="font-bold text-center text-lg">Registro completo</p>
      </div>
    </>
  );
}

export default CreatedUserMessage;
