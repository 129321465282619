import { format } from "date-fns";
import { es } from "date-fns/locale";
import { capitalizeFirstLetter } from "./textUtils";

export function formatDate(inputDate: string): string {
  const [datePart, timePart] = inputDate.split(", ");
  const dateObj = new Date(datePart);
  const day = dateObj.getDate();
  const month = dateObj.getMonth() + 1;
  const year = dateObj.getFullYear();

  const formattedDay = String(day).padStart(2, "0");
  const formattedMonth = String(month).padStart(2, "0");

  return `${formattedDay}/${formattedMonth}/${year}`;
}

export function formatDateToSpanishLocale(originalDate?: string): string {
  try {
    if (originalDate) {
      // Format the date using the date-fns library with Spanish locale settings.
      const formattedDate = format(
        new Date(originalDate),
        "EEEE, d 'de' MMMM yyyy",
        { locale: es }
      ).toString();
      // Capitalize the first letter of the formatted date using a utility function.
      return capitalizeFirstLetter(formattedDate);
    } else {
      // If the input date is undefined, log an error message and return an empty string.
      console.error("Error formatting date: originalDate is undefined");
      return "";
    }
  } catch (error) {
    // If there was an error during date formatting, log the error and return the original input date or an empty string.
    console.error("Error formatting date:", error);
    return originalDate || "";
  }
}

export function addDaysToString(date: Date, days: number): string {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  const stringDate = newDate.toISOString().substring(0, 10);
  return stringDate;
}

export function restPeriodDays(fechas: string[]): number | null {
  if (fechas.length !== 2) {
    console.error("The function requires an array of two dates.");
    return null;
  }

  // Create Date objects from the date strings
  const fecha1 = new Date(fechas[0]);
  const fecha2 = new Date(fechas[1]);

  // Subtract the dates to get the difference in milliseconds
  const daysMiliseconds = fecha2.getTime() - fecha1.getTime();

  // Convert the difference from milliseconds to days
  const days = daysMiliseconds / (1000 * 60 * 60 * 24);

  return days;
}

export function formatDateToYYYYMMDD(inputDate: string): string {
  try {
    const [datePart] = inputDate.split(", ");
    const [day, month, year] = datePart.split("/");
    const dateObj = new Date(Number(year), Number(month) - 1, Number(day));

    if (isNaN(dateObj.getTime())) {
      throw new Error("Fecha no válida");
    }

    const formattedYear = String(dateObj.getFullYear());
    const formattedMonth = String(dateObj.getMonth() + 1).padStart(2, "0");
    const formattedDay = String(dateObj.getDate()).padStart(2, "0");

    return `${formattedYear}-${formattedMonth}-${formattedDay}`;
  } catch (error) {
    console.error("Error formatting date:", error);
    return "";
  }
}

export function formatDateToYYYYMMDDFromISO(isoDate: string): string {
  try {
    // Create a Date object from the ISO string
    const dateObj = new Date(isoDate);

    if (isNaN(dateObj.getTime())) {
      throw new Error("Invalid date");
    }

    // Extract the year, month, and day
    const formattedYear = String(dateObj.getFullYear());
    const formattedMonth = String(dateObj.getMonth() + 1).padStart(2, "0");
    const formattedDay = String(dateObj.getDate()).padStart(2, "0");

    // Return the formatted date as yyyy-MM-dd
    return `${formattedYear}-${formattedMonth}-${formattedDay}`;
  } catch (error) {
    console.error("Error formatting date:", error);
    return "";
  }
}

export function formatTime(date: Date): string {
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${hours}:${minutes}`;
}

export function parseTimeStringToDateTime(timeString: string): Date {
  const [hours, minutes] = timeString.split(":").map(Number);
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  return date;
}

export function formatHour(datetime?: string): string {
  if (!datetime) return "";

  const time = datetime.split(" ")[1];
  return time ? time.substring(0, 5) : "";
}

export function convertToISO8601(dateStr: string): string {
  // Asegúrate de que el formato de entrada sea "YYYY-MM-DD HH:mm:ss"
  const dateParts = dateStr.split(' ');

  if (dateParts.length !== 2) {
    throw new Error('Invalid date format. Expected "YYYY-MM-DD HH:mm:ss"');
  }

  const [date, time] = dateParts;

  // Combina la fecha y la hora con "T" y agrega "Z" para indicar UTC.
  const isoDate = `${date}T${time}Z`;

  // Retorna solo la parte sin los milisegundos
  return isoDate;}
