import LinksNavigation from "@/constants/navigation";
import Image from "next/image";
import React from "react";

const Logo: React.FC = () => {
  const links = LinksNavigation();
  return (
    <div className="flex items-center" onClick={links.handleClickHome}>
      <Image
        src="/assets/images/atua.svg"
        alt="Logotipo de ATUA - Alquiler de autos"
        width={90}
        height={36}
        priority={true}
        className="h-auto w-[90px]"
      />
    </div>
  );
};

export default Logo;
