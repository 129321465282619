import ReactDatePicker from "react-datepicker";
import { es } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
import "../../../app/styles.css";
import { addDays } from "date-fns";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

interface Props {
  label: string;
  pickDate: Date;
  setPickDate: (date: Date) => void;
  hour: Date;
  setHour: (hour: Date) => void;
  minDate?: Date;
}

function DateTimePicker({
  label,
  pickDate,
  setPickDate,
  hour,
  setHour,
  minDate,
}: Props) {
  return (
    <>
      <div className="flex-grow items-center align-middle pl-4">
        <p className=" text-[11px] font-semibold ">{label}</p>
        <div className="grid grid-cols-12 pr-2">
          <div className="flex col-span-7 h-6">
            <div className="relative">
              <ReactDatePicker
                className={
                  "pl-2 outline-none w-full text-sm text-gray-600 placeholder-gray-400"
                }
                selected={pickDate}
                onChange={(dateSelected) => {
                  if (dateSelected !== null) {
                    setPickDate(dateSelected);
                  }
                }}
                dateFormat={"dd/MM/yyyy"}
                locale={es}
                minDate={minDate ? addDays(minDate, 1) : new Date()}
                calendarClassName={"atua-date-time-picker-style"}
                dayClassName={(date) => {
                  if (pickDate.toDateString() === date.toDateString()) {
                    return "selected-day";
                  }
                  return "";
                }}
                disabledKeyboardNavigation
                showPopperArrow={false}
                popperPlacement="bottom-start"
              />
              <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                <ChevronDownIcon className="h-3" />
              </div>
            </div>
          </div>
          <div className="flex col-span-5 h-6">
            <div className="relative">
              <ReactDatePicker
                className={
                  "pl-6 outline-none w-full text-sm text-gray-600 placeholder-gray-400"
                }
                selected={hour}
                onChange={(hourSelected) => {
                  if (hourSelected !== null) {
                    setHour(hourSelected);
                  }
                }}
                locale={es}
                minDate={minDate ? minDate : new Date()}
                calendarClassName={"atua-date-time-picker-style"}
                disabledKeyboardNavigation
                showPopperArrow={false}
                popperPlacement="bottom-start"
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={30}
                timeCaption="Hora"
                dateFormat="h:mm aa"
              />
              <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                <ChevronDownIcon className="h-3" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DateTimePicker;
