import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";

interface Props {
  setCompleteCode: Dispatch<SetStateAction<string>>;
}

function CodeInput({ setCompleteCode }: Props) {
  const [code, setCode] = useState<string>("");
  const inputRefs = Array.from({ length: 4 }, () =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useRef<HTMLInputElement>(null)
  );

  useEffect(() => {
    if (code.length === 4) {
      setCompleteCode(code);
    }
  }, [code, setCompleteCode]);

  const handleChange = (index: number, value: string) => {
    const newCode = code.split("");
    newCode[index] = value;
    setCode(newCode.join(""));

    if (value && index < 3) {
      inputRefs[index + 1].current?.focus();
    }
  };

  const handleKeyDown = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Backspace" && index > 0 && !code[index]) {
      inputRefs[index - 1].current?.focus();
    }
  };

  return (
    <div className="flex">
      {Array.from({ length: 4 }).map((_, index) => (
        <input
          key={index}
          ref={inputRefs[index]}
          type="number"
          maxLength={1}
          className="w-12 h-12 text-center pl-3 border rounded-md mr-2"
          value={code[index] || ""}
          onChange={(e) => handleChange(index, e.target.value)}
          onKeyDown={(e) => handleKeyDown(index, e)}
        />
      ))}
    </div>
  );
}

export default CodeInput;
