import Image from "next/image";

interface Props {
  image: string;
  onClick: () => void;
  ariaLabel: string;
}

function FooterSocialMedia({ onClick, image, ariaLabel }: Props) {
  return (
    <Image
      src={`/assets/images/${image}`}
      alt={ariaLabel}
      width={30}
      height={30}
      priority
      className="cursor-pointer h-[30px] w-[30px]"
      onClick={onClick}
      aria-label={ariaLabel}
    />
  );
}

export default FooterSocialMedia;
