import React, { useState } from "react";
import FormsHeading from "../FormsHeading";
import Input from "../../Inputs/Input";
import CheckInput from "@/components/Inputs/CheckInput";
import LinksNavigation from "@/constants/navigation";
import PhoneInputWithCountrySelect, {
  type Value,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import ModalsButton from "@/components/Buttons/ModalsButton";
import useValidatePhoneUserModal from "@/hooks/useValidatePhoneUser";
import ValidatePhoneUserModal from "../validateUser/ValidatePhoneUserModal";
import {
  requestPhoneCodeVerification,
  userVerification,
} from "@/services/api/user";
import useLoginModal from "@/hooks/useLoginModal";
import CreatedUserModal from "./CreatedUserModal";

interface Props {
  validatedEmail?: string;
}

function RegisterBody({ validatedEmail }: Props) {
  const links = LinksNavigation();
  const [name, setName] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState(validatedEmail ?? "");
  const [password, setPassword] = useState("");
  const [isTernsAcepted, setIsTernsAcepted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const validatePhoneUserModal = useValidatePhoneUserModal();
  const loginModal = useLoginModal();

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.slice(0, 4); // Limit to 4 characters
    const numericValue = inputValue.replace(/[^0-9]/g, ""); // Allow only numbers

    setPassword(numericValue);

    if (numericValue.length !== 4) {
      setPasswordError("La contraseña debe ser numérica de 4 dígitos");
    } else {
      setPasswordError("");
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = event.target.value;
    setEmail(newEmail);

    if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(newEmail)) {
      setEmailError("El correo electrónico no es válido");
    } else {
      setEmailError("");
    }
  };

  const isFormValid =
    name &&
    lastname &&
    emailError === "" &&
    passwordError === "" &&
    phone !== "" &&
    email !== "" &&
    isTernsAcepted;

  const handleRegister = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent form submission

    if (isFormValid) {
      try {
        const res = await userVerification({ body: { email } });

        if (res.code_transaction === "OK") {
          await requestPhoneCode(); // Ensure this is called before opening the modal
          validatePhoneUserModal.onOpen();
        }
      } catch (error) {
        loginModal.onOpen();
        console.error("Error verifying user:", error);
      }
    }
  };

  const requestPhoneCode = async () => {
    try {
      const body = {
        phone: phone,
      };
      await requestPhoneCodeVerification({ body });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      <form className="flex flex-col gap-4" onSubmit={handleRegister}>
        <FormsHeading title="Bienvenido a Atua" subtitle="¡Creá una cuenta!" />
        <Input
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          label="Nombres"
          type="text"
          required
        />

        <Input
          id="lastname"
          value={lastname}
          onChange={(e) => setLastname(e.target.value)}
          label="Apellidos"
          type="text"
          required
        />
        <Input
          id="email"
          label="Email"
          required
          value={email}
          onChange={handleEmailChange}
          errors={emailError}
        />
        <Input
          id="password"
          label="Password"
          type="password"
          disabled={isLoading}
          required
          value={password}
          onChange={handlePasswordChange}
          errors={passwordError}
        />
        <PhoneInputWithCountrySelect
          international
          countryCallingCodeEditable={false}
          defaultCountry="AR"
          value={phone}
          onChange={(phone: Value) => {
            setPhone(phone);
          }}
          style={{
            borderWidth: "2px",
            borderColor: phoneError ? "#EF4444" : "#D4D4D4",
            background: "white",
            padding: "11px",
            color: "#A1A1AA",
            fontWeight: "300",
            borderRadius: "6px",
            outline: "none",
          }}
        />
        {phoneError && (
          <p className="text-red-500 text-xs -mt-2">{phoneError}</p>
        )}

        <CheckInput
          onToggle={(value) => {
            setIsTernsAcepted(value);
          }}
        >
          <p>
            {"Al continuar acepto todos los "}
            <span
              onClick={links.handleClickTerms}
              className="text-violet-700 cursor-pointer hover:underline"
            >
              términos y condiciones.
            </span>
          </p>
        </CheckInput>
        <div className="pt-5">
          <ModalsButton
            label="Registraté"
            disabled={isLoading || !isFormValid}
            type="submit"
          />
        </div>
      </form>
      {validatePhoneUserModal.isOpen && (
        <ValidatePhoneUserModal
          name={name}
          lastname={lastname}
          phone={phone}
          email={email}
          password={password}
        />
      )}
      <CreatedUserModal email={email} password={password} />
    </>
  );
}

export default RegisterBody;
