import React, { Dispatch, SetStateAction } from "react";
import CodeInput from "./CodeInput";
import { requestPhoneCodeVerification } from "@/services/api/user";

interface Props {
  phone: string | undefined;
  setCode: Dispatch<SetStateAction<string>>;
}

function ValidatePhoneUserMessage({ phone, setCode }: Props) {
  const requestPhoneCode = async () => {
    try {
      const body = {
        phone: phone,
      };
      const res = await requestPhoneCodeVerification({
        body: body,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      <div className="sitems-center">
        <p className="font-bold text-center text-lg">
          Confirmá tu número de teléfono
        </p>
        <p className="text-center text-sm py-4">
          Introduce el código que te enviamos por SMS al {phone}
        </p>

        <div className="flex w-full items-center justify-center pt-4 pb-8">
          <CodeInput setCompleteCode={setCode} />
        </div>

        <p className="text-sm text-center">
          ¿No has recibido un código?{" "}
          <span
            className="font-semibold underline cursor-pointer"
            onClick={requestPhoneCode}
          >
            Reenviar código
          </span>
        </p>
      </div>
    </>
  );
}

export default ValidatePhoneUserMessage;
