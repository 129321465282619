"use client";
import React from "react";
import SmallModal from "../SmallModal";
import useDeleteAccountModal from "@/hooks/useDeleteAccountModal";
import useAlertModal from "@/hooks/useAlertModal";
import SupportLink from "@/components/SupportLink";

interface Props {
  onSubmit?: () => void;
  title?: string;
  subtitle?: string;
  support?: boolean;
}

const AlertModal = ({ onSubmit, title, subtitle, support = false }: Props) => {
  const alertModal = useAlertModal();

  return (
    <SmallModal
      isOpen={alertModal.isOpen}
      title="Alerta"
      onClose={alertModal.onClose}
      body={
        <>
          <div className="items-center text-center space-y-2">
            <p className="text-lg font-bold">{title}</p>
            <p>{subtitle}</p>
          </div>
          {support && (
            <div className="flex justify-center items-center pt-4">
              <SupportLink />
            </div>
          )}
        </>
      }
      actionLabel="Aceptar"
      onSubmit={alertModal.onClose}
    />
  );
};

export default AlertModal;
