import React, { ReactNode } from "react";

interface Props {
  label?: string;
  icon?: ReactNode;
  divider?: Boolean;
  onClick?: () => void;
}

function MenuItems({ onClick, label, icon, divider }: Props) {
  return (
    <>
      <div
        className={`px-4 py-2 items-center hover:bg-gray-100 transition flex text-[13px] gap-x-2 cursor-pointer
        ${divider ? "border-t-2 border-gray-200" : ""}`}
        onClick={onClick}
      >
        {icon}

        <div>{label}</div>
      </div>
    </>
  );
}

export default MenuItems;
