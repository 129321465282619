// ThemeButton.js
"use client";
import Image from "next/image";
import LinksNavigation from "@/constants/navigation";

interface Props {
  isBooking?: boolean;
}

const WhatsappButton = ({ isBooking }: Props) => {
  const links = LinksNavigation();

  const handleButtonClick = () => {
    if (isBooking) {
      // For booking clicks
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "button_click",
        category: "whatsapp_button",
        action: "click",
        label: "Booking",
      });
      links.handleClickWhatsappBooking();
    } else {
      // For support clicks
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "button_click",
        category: "whatsapp_button",
        action: "click",
        label: "Support",
      });
      links.handleClickWhatsappSoporte();
    }
  };

  return (
    <>
      <button
        className="hover:scale-110 active:scale-100 rounded-full hover:menu-items-hover text-text duration-200 min-w-[45px]"
        onClick={handleButtonClick}
      >
        <Image
          src={"/assets/images/Whatsapp2.svg"}
          alt={"whatsapp"}
          width={0}
          height={0}
          priority
          className="cursor-pointer w-[45px] h-auto"
        />
      </button>
    </>
  );
};

export default WhatsappButton;
