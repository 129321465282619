import { create } from "zustand";

interface EmailStore {
  email: string;
  setEmail: (email: string) => void;
  resetEmail: () => void;
}

const useEmailStore = create<EmailStore>((set) => ({
  email: "",
  setEmail: (email) => set({ email }),
  resetEmail: () => set({ email: "" }),
}));

export default useEmailStore;
