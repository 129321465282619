import { createConnection } from "./connection";

export async function getUserInformation({ token }: { token: string }) {
  try {
    const res = await createConnection({
      url: "/users/summary/",
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    return res.user_info;
  } catch (error) {
    throw new Error("Error fetching data: ");
  }
}

export async function updateUserProfile({
  token,
  data,
}: {
  token: string;
  data: FormData;
}) {
  try {
    const res = await createConnection({
      url: "/users/update/",
      method: "PUT",
      data: data,
      headers: { Authorization: `Bearer ${token}` },
    });
    return res;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw new Error("Error fetching data");
  }
}

export async function getUserDocumentVerication({ token }: { token: string }) {
  try {
    // Send a GET request
    const res = await createConnection({
      url: "/users/document-verification/summary/",
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    // Extract and return the "user_info" property from the API response data.
    return res;
  } catch (error) {
    // If there's an error during the data fetching process, throw a new Error with a generic message.
    throw new Error("Error fetching data: ");
  }
}

export async function updateUserDocumentVerication({
  token,
  data,
  params,
}: {
  token: string;
  data: FormData;
  params: {
    document_verification_id: number | null;
  };
}) {
  try {
    // Send a GET request to retrieve user trip data with authorization
    const res = await createConnection({
      url: "/users/document-verification/update/",
      method: "PUT",
      data: data,
      params: params,
      headers: { Authorization: `Bearer ${token}` },
    });
    return res;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw new Error("Error fetching data");
  }
}

export async function deleteUser({ token }: { token: string }) {
  // Send a GET request
  const res = await createConnection({
    url: "/users/delete/",
    method: "POST",
    headers: { Authorization: `Bearer ${token}` },
  });
  // Extract and return the "user_info" property from the API response data.
  return res;
}

export async function requestChangePassword({
  body,
}: {
  body: {
    email: string | null;
  };
}) {
  try {
    // Send a GET request to retrieve user trip data with authorization
    const res = await createConnection({
      url: "/users/process-recovery-password/",
      method: "POST",
      body: body,
    });
    return res;
    // Extract and return the "data" property from the API response data.
  } catch (error: any) {
    if (
      error.response &&
      error.response.data &&
      error.response.data.message &&
      error.response.data.message.email
    ) {
      return error.response.data.message.email;
    }
    if (error.response && error.response.data && error.response.data.message) {
      return error.response.data.message;
    }
  }
}

export async function changeUserPassword({
  body,
}: {
  body: {
    email: string | null;
    code: string | null;
    new_password: string | null;
  };
}) {
  try {
    // Send a GET request to retrieve user trip data with authorization
    const res = await createConnection({
      url: "/users/recovery-password/",
      method: "POST",
      body: body,
    });
    return res;
    // Extract and return the "data" property from the API response data.
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.message) {
      return error.response.data.message;
    }
  }
}

export async function requestPhoneCodeVerification({
  body,
}: {
  body: {
    phone: string | undefined;
  };
}) {
  try {
    // Send a GET request to retrieve user trip data with authorization
    const res = await createConnection({
      url: "/sms/send-verification-code/",
      method: "POST",
      body: body,
    });
    return res;
    // Extract and return the "data" property from the API response data.
  } catch (error) {
    console.error("Error fetching data:", error);
    throw new Error("Error fetching data");
  }
}

export async function phoneCodeVerification({
  body,
}: {
  body: {
    phone: string | undefined;
    code: string | null;
  };
}) {
  try {
    // Send a GET request to retrieve user trip data with authorization
    const res = await createConnection({
      url: "/sms/verification-code/",
      method: "POST",
      body: body,
    });
    return res;
    // Extract and return the "data" property from the API response data.
  } catch (error) {
    console.error("Error fetching data:", error);
    throw new Error("Error fetching data");
  }
}

export async function createUser({
  body,
}: {
  body: {
    phone: string | undefined;
    password: string | undefined;
    email: string | undefined;
    first_name?: string | undefined;
    last_name?: string | undefined;
    document_number?: string | undefined;
    document_type?: string | undefined;
  };
}) {
  try {
    // Send a GET request to retrieve user trip data with authorization
    const res = await createConnection({
      url: "/users/create/",
      method: "POST",
      body: body,
    });
    return res;
    // Extract and return the "data" property from the API response data.
  } catch (error: any) {
    console.error("Error fetching data:", error);
    throw new Error("Error fetching data");
  }
}

export async function userVerification({
  body,
}: {
  body: {
    email: string | undefined;
  };
}) {
  try {
    // Send a GET request to retrieve user trip data with authorization
    const res = await createConnection({
      url: "/users/verification/",
      method: "POST",
      body: body,
    });
    return res;
    // Extract and return the "data" property from the API response data.
  } catch (error: any) {
    console.error("Error fetching data:", error);
    throw new Error("Error fetching data");
  }
}
