import { XMarkIcon } from "@heroicons/react/24/solid";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import ModalsButton from "../Buttons/ModalsButton";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  body?: ReactNode;
  actionLabel?: string;
  onSubmit?: () => void;
  disabled?: boolean;
  secondaryAction?: () => void;
  secondaryActionLabel?: string;
}

function SmallModal({
  isOpen,
  onClose,
  onSubmit,
  title,
  body,
  actionLabel,
  disabled,
  secondaryAction,
  secondaryActionLabel,
}: Props) {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(isOpen);
  }, [isOpen]);

  const handleClose = useCallback(() => {
    setShowModal(false);
    setTimeout(() => {
      onClose();
    }, 300);
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    if (disabled || !onSubmit) {
      return;
    }
    onSubmit();
  }, [onSubmit, disabled]);

  const handleSecondaryAction = useCallback(() => {
    if (disabled || !secondaryAction) {
      return;
    }

    secondaryAction();
  }, [secondaryAction, disabled]);

  if (!isOpen) {
    return null;
  }
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto  fixed  inset-0  z-50  outline-none  focus:outline-none bg-neutral-800/70">
        <div className=" relative w-[400px] my-6 mx-auto h-auto ">
          {/*content*/}
          <div
            className={` translate duration-300 h-full ${
              showModal ? "translate-y-0" : "translate-y-full"
            } ${showModal ? "opacity-100" : "opacity-0"}`}
          >
            <div className=" translate h-full lg:h-auto md:h-auto border-0  rounded-lg  shadow-lg  relative  flex  flex-col  w-full  bg-white  outline-none  focus:outline-none">
              {/*header*/}
              <div className=" flex  items-center  p-5 rounded-t justify-center relative border-b-[1px] ">
                <button
                  className=" p-1 border-0  hover:opacity-70 transition absolute left-9"
                  onClick={handleClose}
                >
                  <XMarkIcon className="h-4" />
                </button>
                <div className=" text-base font-semibold">{title}</div>
              </div>
              {/*body*/}
              <div className="relative p-5 flex-auto">{body}</div>
              {/*footer*/}
              <div className="flex flex-col gap-2 p-5">
                <div className=" flex flex-row items-center gap-3 w-full ">
                  {secondaryAction && secondaryActionLabel && (
                    <ModalsButton
                      disabled={disabled}
                      label={secondaryActionLabel}
                      onClick={handleSecondaryAction}
                      outline
                    />
                  )}
                  {actionLabel && (
                    <ModalsButton
                      disabled={disabled}
                      label={actionLabel}
                      onClick={handleSubmit}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SmallModal;
