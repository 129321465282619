import React from "react";

interface Props {
  label: string;
  onClick?: () => void;
  ariaLabel?: string;
}

function FooterLink({ onClick, label, ariaLabel }: Props) {
  return (
    <p
      onClick={onClick}
      className="text-xs/[10px] cursor-pointer"
      aria-label={ariaLabel}
    >
      {label}
    </p>
  );
}

export default FooterLink;
