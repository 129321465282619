import React from "react";

interface Props {
  title: string;
}

function FooterTitle({ title }: Props) {
  return <h5 className="font-bold text-gray-800 text-sm">{title}</h5>;
}

export default FooterTitle;
