"use client";
import React, { ReactNode, useEffect } from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import RegisterModal from "./Modals/register/RegisterModal";
import LoginModal from "./Modals/login/LoginModal";
import { useJsApiLoader } from "@react-google-maps/api";
import WhatsappButton from "./Header/WhatsappButton";
import useEmailStore from "@/hooks/useEmailStore";

interface Props {
  children?: ReactNode;
  isHomePage?: Boolean;
  isPostACar?: Boolean;
}
//List of libraries to be loaded from Google Maps API.
const libraries = ["places" as const];

function MainScreen({ children, isHomePage, isPostACar }: Props) {
  const { email } = useEmailStore();

  //Load Google Maps API libraries using useJsApiLoader hook.
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY as string,
    libraries: libraries,
  });

  if (isLoaded) {
    return (
      <>
        <Header isHomePage={isHomePage} />
        <RegisterModal validatedEmail={email} />
        <LoginModal validatedEmail={email} />
        {children}
        <Footer />
        {!isHomePage && (
          <div className="fixed bottom-10 z-50 right-5 flex justify-end">
            <WhatsappButton isBooking={!isPostACar} />
          </div>
        )}
      </>
    );
  }
  // If Google Maps API libraries are not loaded yet, return an empty fragment.
  return <></>;
}

export default MainScreen;
