import React, { ReactNode } from "react";

interface Props {
  label: string;
  onClick: () => void;
}

export default function LinksNavbarItem({ label, onClick }: Props) {
  return (
    <>
      <div
        className="flex items-center gap-x-2 text-sm font-semibold cursor-pointer hover:scale-105"
        onClick={onClick}
      >
        <p>{label}</p>
      </div>
    </>
  );
}
