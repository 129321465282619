import { XMarkIcon } from "@heroicons/react/24/solid";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import ModalsButton from "../Buttons/ModalsButton";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  title?: string;
  body?: ReactNode;
  footer?: ReactNode;
  actionLabel?: string;
  disabled?: boolean;
  secondaryAction?: () => void;
  secondaryActionLabel?: string;
}

const Modal = ({
  isOpen,
  onClose,
  onSubmit,
  title,
  body,
  actionLabel,
  footer,
  disabled,
  secondaryAction,
  secondaryActionLabel,
}: Props) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(isOpen);
  }, [isOpen]);

  const handleClose = useCallback(() => {
    if (disabled) return;

    setShowModal(false);
    setTimeout(onClose, 300);
  }, [onClose, disabled]);

  const handleSubmit = useCallback(() => {
    if (disabled || !onSubmit) return;

    onSubmit();
  }, [onSubmit, disabled]);

  const handleSecondaryAction = useCallback(() => {
    if (disabled || !secondaryAction) return;

    secondaryAction();
  }, [secondaryAction, disabled]);

  if (!isOpen) return null;

  return (
    <>
      <div className="flex justify-center items-center fixed inset-0 z-50 overflow-x-hidden overflow-y-auto bg-neutral-800/70 outline-none focus:outline-none">
        <div className="relative w-full md:w-4/7 lg:w-3/7 xl:w-2/6 my-6 mx-auto max-h-[calc(100vh-1rem)] h-full lg:h-auto">
          <div
            className={`translate duration-300 h-full ${
              showModal
                ? "translate-y-0 opacity-100"
                : "translate-y-full opacity-0"
            }`}
          >
            <div className="flex flex-col w-full h-full lg:h-auto border-0 rounded-lg shadow-lg bg-white outline-none max-h-[calc(100vh-1rem)]">
              {/* Header */}
              <div className="flex items-center p-3 rounded-t justify-center relative border-b-[1px]">
                <button
                  className="absolute left-9 p-1 border-0 hover:opacity-70 transition"
                  onClick={handleClose}
                >
                  <XMarkIcon className="h-4" />
                </button>
                <div className="text-base font-semibold">{title}</div>
              </div>
              {/* Body */}
              <div className="relative p-6 flex-auto overflow-y-auto max-h-[calc(100vh-9rem)]">
                {body}
              </div>
              {/* Footer */}
              <div className="flex flex-col gap-2 p-5">
                <div className="flex flex-row items-center gap-3 w-full">
                  {secondaryAction && secondaryActionLabel && (
                    <ModalsButton
                      disabled={disabled}
                      label={secondaryActionLabel}
                      onClick={handleSecondaryAction}
                      outline
                    />
                  )}
                  {actionLabel && (
                    <ModalsButton
                      disabled={disabled}
                      label={actionLabel}
                      onClick={handleSubmit}
                    />
                  )}
                </div>
                {footer}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
