"use client";

import Image from "next/image";

interface AvatarProps {
  photo?: string;
  height?: number;
  width?: number;
}

const Avatar: React.FC<AvatarProps> = ({ photo, height, width }) => {
  return (
    <Image
      className="rounded-full object-cover"
      height={height || "30"}
      width={width || "30"}
      blurDataURL="/assets/images/placeholder.jpg"
      placeholder="blur"
      loading="lazy"
      quality="100"
      alt="Avatar"
      src={photo || "/assets/images/placeholder.jpg"}
    />
  );
};

export default Avatar;
