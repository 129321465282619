"use client";
import React from "react";
import FooterTitle from "./FooterTitle";
import FooterLink from "./FooterLink";
import FooterSocialMedia from "./FooterSocialMedia";
import FooterStore from "./FooterStore";
import LinksNavigation from "@/constants/navigation";

function Footer() {
  const links = LinksNavigation();

  return (
    <footer className="grid grid-cols-1 lg:grid-cols-4 gap-y-10 px-30 py-8 mt-8 bg-gray-100 text-gray-600 justify-center text-center">
      {/* Accesos */}
      <div className="footer-column">
        <FooterTitle title="Accesos" />
        <FooterLink
          onClick={links.handleClickTerms}
          label="Términos y Condiciones"
          ariaLabel="Términos y Condiciones de ATUA Rent a Car"
        />
        <FooterLink
          onClick={links.handleClickPolicies}
          label="Políticas de privacidad"
          ariaLabel="Políticas de privacidad de ATUA Rent a Car"
        />
      </div>

      {/* Contacto */}
      <div className="footer-column">
        <FooterTitle title="¿Tenés dudas? Escribinos" />
        <FooterLink
          onClick={links.handleClickWhatsappBooking}
          label="Alquileres: +54 9 11 3144-4404"
          ariaLabel="Contacto de alquileres"
        />
        <FooterLink
          onClick={links.handleClickWhatsappSoporte}
          label="Servicio al cliente: +54 9 11 4445-9333"
          ariaLabel="Servicio al cliente de ATUA Rent a Car"
        />
        <FooterLink
          label="support@atuarental.com"
          ariaLabel="Correo de soporte de ATUA Rent a Car"
        />
      </div>

      {/* Ubicación */}
      <div className="footer-column">
        <FooterTitle title="ATUA RENT A CAR" />
        <FooterLink
          onClick={links.handleClickGoogleMaps}
          label="Costa Rica 3966, Palermo (CABA) CP.1176."
          ariaLabel="Dirección de ATUA Rent a Car"
        />
        <FooterLink
          label="de lunes a viernes de 9:00 a 19.00"
          ariaLabel="Horario de atención de lunes a viernes"
        />
        <FooterLink
          label="sábados y domingos de 9:00 a 18.00"
          ariaLabel="Horario de atención de fines de semana"
        />
      </div>

      {/* Redes Sociales y Tiendas */}
      <div className="footer-column">
        <div className="flex items-center gap-3 justify-center">
          <FooterSocialMedia
            onClick={links.handleClickFacebook}
            image="facebook.svg"
            ariaLabel="Visitar página de Facebook de ATUA"
          />
          <FooterSocialMedia
            onClick={links.handleClickInstagram}
            image="instagram.svg"
            ariaLabel="Visitar página de Instagram de ATUA"
          />
          <FooterSocialMedia
            onClick={links.handleClickWhatsappSoporte}
            image="whatsapp.svg"
            ariaLabel="Contactar vía WhatsApp con ATUA"
          />
        </div>
        <div className="flex items-center gap-3 justify-center">
          <FooterStore
            onClick={links.handleClickPlayStore}
            image="play-store.svg"
            ariaLabel="Descargar aplicación de ATUA en Google Play Store"
          />
          <FooterStore
            onClick={links.handleClickAppStore}
            image="app-store.svg"
            ariaLabel="Descargar aplicación de ATUA en App Store"
          />
        </div>
      </div>
    </footer>
  );
}

export default Footer;
