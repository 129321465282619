import React from "react";

interface Props {
  label: string;
  onClick?: () => void;
  disabled?: boolean;
  outline?: boolean;
  small?: boolean;
  disabledText?: string;
  type?: "button" | "submit" | "reset"; // Add the type prop
}

function ModalsButton({
  label,
  onClick,
  disabled,
  outline,
  small,
  disabledText,
  type = "button",
}: Props) {
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={`
        relative
        disabled:opacity-50
        disabled:cursor-not-allowed disabled:hover:scale-100
        rounded-lg 
        hover:opacity-80
        hover:scale-[1.02]
        transition
        w-full
        ${outline ? "bg-white" : "bg-violet-700"}
        ${outline ? "border-black" : "border-violet-700"}
        ${outline ? "text-black" : "text-white"}
        ${small ? "text-sm" : "text-md"}
        ${small ? "py-2" : "py-3"}
        ${small ? "font-light" : "font-semibold"}
        ${small ? "border-[1px]" : "border-2"}
      `}
    >
      {disabled ? (
        disabledText ? (
          <p>{disabledText}</p>
        ) : (
          <p>{label}</p>
        )
      ) : (
        <p>{label}</p>
      )}
    </button>
  );
}

export default ModalsButton;
