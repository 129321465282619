// carSearchUtils.js
"use client";
import { addDays } from "date-fns";
import { addDaysToString, formatTime } from "@/commons/helpers/dateUtils";
import useBookingDates from "@/hooks/useBookingDates";
import { useRouter, useSearchParams } from "next/navigation";

const SearchCars = () => {
  const router = useRouter();
  const searchParams = useSearchParams();

  //booking date and hours variables
  const { location, startDate, startHour, endDate, endHour } =
    useBookingDates();

  const startDateString = addDaysToString(
    startDate ?? addDays(new Date(), 1),
    0
  );
  const endDateString = addDaysToString(endDate ?? addDays(new Date(), 3), 0);
  const startHourString = formatTime(startHour);
  const endHourString = formatTime(endHour);

  const handleSearch = (newLocation?: string) => {
    if (newLocation === null || location === "") {
      newLocation = location;
    }
    const period = startDateString + "," + endDateString;

    if (searchParams && newLocation) {
      const params = new URLSearchParams(searchParams);
      params.set("location", newLocation.toString());
      params.set("period", period);
      params.set("schedule_delivery_gte", startHourString);
      params.set("schedule_return_lte", endHourString);

      router.replace(`/cars/search?${params.toString()}`);
    }
  };

  return {
    handleSearch,
  };
};

export default SearchCars;
