"use client";
import React, { useCallback, useState } from "react";
import Modal from "../Modal";
import useLoginModal from "@/hooks/useLoginModal";
import useRegisterModal from "@/hooks/useRegisterModal";
import LoginBody from "./LoginBody";
import LoginFooter from "./LoginFooter";

interface Props {
  validatedEmail?: string;
}

const LoginModal = ({ validatedEmail }: Props) => {
  const loginModal = useLoginModal();
  const registerModal = useRegisterModal();

  const onToggle = useCallback(() => {
    loginModal.onClose();
    registerModal.onOpen();
  }, [loginModal, registerModal]);

  return (
    <Modal
      isOpen={loginModal.isOpen}
      title="Inicia Sesión"
      onClose={loginModal.onClose}
      body={<LoginBody validatedEmail={validatedEmail} />}
      footer={<LoginFooter onClick={onToggle} />}
    />
  );
};

export default LoginModal;
