/**
 * Header.js
 * This file contains the Header component, which represents the header section of the application.
 */

import React from "react";
import SearchBar from "./SearchBar/SearchBar";
import Logo from "./Logo";
import UserMenu from "./UserMenu";
import LinksNavBar from "./LinksNavBar";

/**
 * Props interface for the Header component.
 * @interface Props
 * @property {boolean} [isHomePage] - Indicates if the current page is the home page.
 */

interface Props {
  isHomePage?: Boolean;
}

/**
 * Header component represents the header section of the application. It consists of the logo,
 * search bar (only on non-homepage), and the user menu.
 */

function Header({ isHomePage }: Props) {
  return (
    <header
      className={`sticky top-0 z-40 bg-white shadow-md px-3 py-2 
      flex justify-between items-center gap-x-6 ${
        isHomePage ? "h-[72px]" : " h-20"
      }`}
    >
      {/* Render the logo */}
      <Logo />

      {/* Render the search bar on non-homepage */}
      <div
        className={`${isHomePage ? "lg:px-0" : "lg:px-8"} w-full`}
      >
        {!isHomePage && <SearchBar />}
        {isHomePage && <LinksNavBar />}
      </div>

      {/* Render the user menu */}
      <UserMenu isHomePage={isHomePage} />
    </header>
  );
}

export default React.memo(Header);
